import slugify from "slugify";

export default {
	computed: {
		/**
		 * Filters tab items that are active
		 * */
		activeTabs: function () {
			return this._.filter(this.items, 'active')
		}
	},
	methods: {
		getNameData(name) {
			return slugify('UI_' + this.$router.currentRoute.name + '_' + this.$options.name.replace('.vue', '') + '_' + name).toLowerCase();
		},
		keepData(name, data) {
			localStorage.setItem(this.getNameData(name), data);
		},
		restoreData(name, defaultData) {
			return localStorage.getItem(this.getNameData(name)) !== null ? localStorage.getItem(this.getNameData(name)) : defaultData;
		}
	},
}
